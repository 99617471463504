<template>
  <div>
    <div class="form-wizard-header">
      <h2 class="text-uppercase font-weight-bolder mb-10">
        Are you applying<br/>
        with someone else</h2>
      <form>
        <b-form-group>
          <b-form-checkbox switch
                           v-model="withCobuyer"
                           @change="disableCobuyerFields"
          >Yes, I am applying with a co-buyer</b-form-checkbox>
          <b-form-checkbox switch
                           style="padding-top: 1rem"
                           v-model="withDependents"
                           @change="disableDependentsFields"
          >Yes, I have dependent(s)</b-form-checkbox>
        </b-form-group>
        <transition name="fade">
          <div v-if="!disableFields" class="cobuyer-fields">
            <b-form-group
              :class="{ 'has-error': $v.cobuyername.$error }">
              <label class="font-size-xs">Co-buyer's full name</label>
              <b-input v-model.trim="cobuyername"></b-input>
              <span class="help-block"
                    v-if="$v.cobuyername.$error && !$v.cobuyername.required">
              Co-buyer's name is required</span>
            </b-form-group>
            <b-form-group
              :class="{ 'has-error': $v.cobuyeremail.$error }">
              <label class="font-size-xs">Co-buyer's email</label>
              <b-input v-model.trim="cobuyeremail"></b-input>
              <span class="help-block"
                    v-if="$v.cobuyeremail.$error
                    && (!$v.cobuyeremail.required || !$v.cobuyeremail.email)">
              Co-buyer's email is not valid</span>
            </b-form-group>
          </div>
        </transition>

        <div>
          <transition name="fade">
          <b-form-group v-if="!disableDepFields"
            :class="{ 'has-error': $v.dependents.$error }">
            <label class="font-size-xs">Number of Dependents</label>
            <b-form-input
              v-model="dependents"
              type="number"
              placeholder="How many dependents do you have?"
            ></b-form-input>
            <span class="help-block"
                  v-if="$v.dependents.$error && !$v.dependents.required">
            Number of dependents is required</span>
          </b-form-group>
          </transition>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { email, required } from 'vuelidate/lib/validators';

export default {
  name: 'CobuyerForm',
  data() {
    return {
      withCobuyer: true,
      withDependents: false,
      cobuyername: '',
      cobuyeremail: '',
      disableFields: false,
      disableDepFields: true,
      dependents: 0,
    };
  },
  validations() {
    if (this.needInputValidation) {
      return {
        cobuyername: {
          required,
        },
        dependents: {
          required,
        },
        cobuyeremail: {
          required,
          email,
        },
        form: ['cobuyername', 'cobuyeremail', 'dependents'],
      };
    }
    return {
      cobuyername: {},
      cobuyeremail: {},
      dependents: {
        required,
      },
      form: ['cobuyername', 'cobuyeremail', 'dependents'],
    };
  },
  computed: {
    needInputValidation() {
      return this.withCobuyer;
    },
  },
  methods: {
    validate() {
      this.$v.form.$touch();
      const isValid = !this.$v.form.$invalid;
      this.$emit('on-validate', this.$data, isValid);
      return isValid;
    },
    disableCobuyerFields() {
      this.disableFields = !this.disableFields;
      this.$v.form.$reset();
      this.cobuyeremail = '';
      this.cobuyername = '';
    },
    disableDependentsFields() {
      this.disableDepFields = !this.disableDepFields;
      this.$v.form.$reset();
      this.dependents = '0';
    },
  },
};

</script>

<style lang="scss">
  .cobuyer-fields {
    margin-top: 1.5rem;
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
</style>

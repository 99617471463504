var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"form-wizard-header"},[_vm._m(0),_c('form',{attrs:{"autocomplete":"off"}},[_c('b-form-group',{class:{ 'has-error': _vm.$v.whenTimePeriod.$error }},[_c('label',{staticClass:"font-size-xs"},[_vm._v("When could you move if you qualified for OwnHome")]),_c('b-form-select',{attrs:{"required":true,"options":_vm.possibleTimePeriods},model:{value:(_vm.whenTimePeriod),callback:function ($$v) {_vm.whenTimePeriod=$$v},expression:"whenTimePeriod"}}),(_vm.$v.whenTimePeriod.$error &&  !_vm.$v.whenTimePeriod.required)?_c('span',{staticClass:"help-block"},[_vm._v(" Move in Time period is required ")]):_vm._e()],1),_c('b-form-group',{class:{ 'has-error': _vm.$v.suburbList.$error }},[_c('label',{staticClass:"font-size-xs"},[_vm._v("Suburbs")]),_c('b-form-tags',{staticClass:"mb-2",staticStyle:{"padding":"0px","border":"0px"},attrs:{"tag-validator":_vm.tagValidator,"no-outer-focus":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var tags = ref.tags;
var inputAttrs = ref.inputAttrs;
var inputHandlers = ref.inputHandlers;
var tagVariant = ref.tagVariant;
var addTag = ref.addTag;
var removeTag = ref.removeTag;
return [_c('b-input-group',{staticClass:"mb-2"},[_c('b-form-input',_vm._g(_vm._b({staticClass:"form-control",attrs:{"disabled":_vm.dontknow,"placeholder":"Search suburbs...","list":"suburb-list"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }addTag(); _vm.userInput=''}},model:{value:(_vm.userInput),callback:function ($$v) {_vm.userInput=$$v},expression:"userInput"}},'b-form-input',inputAttrs,false),inputHandlers)),_c('b-input-group-append',[_c('b-button',{attrs:{"disabled":_vm.dontknow,"variant":"primary"},on:{"click":function($event){addTag(); _vm.userInput=''}}},[_vm._v("Add")])],1)],1),_c('div',{staticClass:"d-inline-block",staticStyle:{"font-size":"1rem","overflow-y":"scroll"},attrs:{"id":"noscrollbar"}},[_c('div',[(_vm.$v.suburbList.$error && !_vm.$v.suburbList.required)?_c('span',{staticClass:"help-block"},[_vm._v(" Suburb is required ")]):_vm._e()]),_c('transition',{attrs:{"name":"fade"}},[(!_vm.dontknow)?_c('div',_vm._l((tags),function(tag){return _c('b-form-tag',{key:tag,staticClass:"mr-1 mb-1",staticStyle:{"align-content":"center","vertical-align":"center","align-items":"center !important"},attrs:{"limit":5,"tag-validator":_vm.tagValidator,"title":tag,"variant":tagVariant},on:{"remove":function($event){return removeTag(tag)}}},[_vm._v(_vm._s(tag))])}),1):_vm._e()])],1)]}}]),model:{value:(_vm.suburbList),callback:function ($$v) {_vm.suburbList=$$v},expression:"suburbList"}}),_c('datalist',{attrs:{"id":"suburb-list"}},_vm._l((_vm.filterSuburbs),function(ref){
var suburb = ref.suburb;
var postcode = ref.postcode;
var state = ref.state;
return _c('option',{key:suburb.ssc_code},[_vm._v(_vm._s(suburb)+", "+_vm._s(postcode)+" ("+_vm._s(state)+")")])}),0)],1),_c('b-form-group',[_c('b-form-checkbox',{attrs:{"switch":""},on:{"change":function($event){return _vm.onChangeIncludeSurrounding($event)}},model:{value:(_vm.includesurrounding),callback:function ($$v) {_vm.includesurrounding=$$v},expression:"includesurrounding"}},[_vm._v("Include surrounding suburbs")])],1),_c('b-form-group',[_c('b-form-checkbox',{attrs:{"switch":""},on:{"change":function($event){return _vm.onChangeDontKnow($event)}},model:{value:(_vm.dontknow),callback:function ($$v) {_vm.dontknow=$$v},expression:"dontknow"}},[_vm._v("I don't know")])],1)],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h2',{staticClass:"text-uppercase font-weight-bolder mb-10"},[_vm._v(" What suburb are "),_c('br'),_vm._v(" you looking at, "),_c('br'),_vm._v(" and when?")])}]

export { render, staticRenderFns }
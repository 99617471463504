<template>
  <div>
    <div class="form-wizard-header">
      <h2 class="text-uppercase font-weight-bolder mb-10">
        What suburb are <br/>
        you looking at, <br/>
        and when?</h2>
      <form autocomplete="off">
        <b-form-group :class="{ 'has-error': $v.whenTimePeriod.$error }">
          <label class="font-size-xs">When could you move if you qualified for OwnHome</label>
          <b-form-select v-model="whenTimePeriod"
                         :required="true"
                         :options="possibleTimePeriods"></b-form-select>
          <span class="help-block"
                v-if="$v.whenTimePeriod.$error &&  !$v.whenTimePeriod.required">
                    Move in Time period is required
                  </span>
        </b-form-group>
        <b-form-group
          :class="{ 'has-error': $v.suburbList.$error }">
          <label class="font-size-xs">Suburbs</label>
          <b-form-tags v-model="suburbList"
                       :tag-validator="tagValidator"
                       no-outer-focus
                       class="mb-2"
                       style="padding: 0px; border: 0px">
            <template v-slot="{ tags, inputAttrs, inputHandlers, tagVariant, addTag, removeTag }">
              <b-input-group class="mb-2">
                <b-form-input
                  :disabled="dontknow"
                  v-model="userInput"
                  v-bind="inputAttrs"
                  v-on="inputHandlers"
                  placeholder="Search suburbs..."
                  class="form-control"
                  list="suburb-list"
                  @keyup.enter="addTag(); userInput=''"
                ></b-form-input>
                <b-input-group-append>
                  <b-button :disabled="dontknow"
                            @click="addTag(); userInput=''"
                            variant="primary">Add</b-button>
                </b-input-group-append>
              </b-input-group>
              <div class="d-inline-block" id="noscrollbar"
                   style="font-size: 1rem;
                    overflow-y: scroll">
                <div>
                  <span class="help-block"
                        v-if="$v.suburbList.$error && !$v.suburbList.required">
                    Suburb is required
                  </span>
                </div>
                <transition name="fade">
                  <div v-if="!dontknow">
                    <b-form-tag
                      :limit="5"
                      v-for="tag in tags"
                      @remove="removeTag(tag)"
                      :tag-validator="tagValidator"
                      :key="tag"
                      :title="tag"
                      :variant="tagVariant"
                      class="mr-1 mb-1"
                      style="align-content: center;
                      vertical-align: center;
                      align-items: center !important;"
                    >{{ tag }}</b-form-tag>
                  </div>
                </transition>
              </div>

            </template>
          </b-form-tags>
            <datalist id="suburb-list">
              <option v-for="{suburb, postcode, state} in filterSuburbs"
                      :key="suburb.ssc_code">{{suburb}}, {{postcode}} ({{state}})</option>
            </datalist>
        </b-form-group>
        <b-form-group>
          <b-form-checkbox switch
             v-model="includesurrounding"
             @change="onChangeIncludeSurrounding($event)"
          >Include surrounding suburbs</b-form-checkbox>
        </b-form-group>
        <b-form-group>
          <b-form-checkbox switch
             @change="onChangeDontKnow($event)"
             v-model="dontknow"
          >I don't know</b-form-checkbox>
        </b-form-group>
      </form>
    </div>
  </div>
</template>
<style>
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
  #noscrollbar {
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll;
  }
  #noscrollbar::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
</style>
<script>
import { requiredUnless } from 'vuelidate/lib/validators';
import suburbJson from '../../../assets/suburbs.json';

export default {
  name: 'TargetSuburbForm',
  data() {
    return {
      userInput: '',
      allSuburbsList: suburbJson.data,
      suburbList: [],
      includesurrounding: true,
      surroundingToggleHistory: true,
      dontknow: false,
      whenTimePeriod: 'Less than 1 Month',
      possibleTimePeriods: [
        { value: 'Less than 1 Month', text: 'Less than 1 Month' },
        { value: '1 - 3 Months', text: '1 - 3 Months' },
        { value: '3 - 6 Months', text: '3 - 6 Months' },
        { value: '6 - 12 Months', text: '6 - 12 Months' },
        { value: '12+ Months', text: '12+ Months' },
      ],
    };
  },
  validations: {
    suburbList: {
      required: requiredUnless('isDontKnowSelected'),
    },
    whenTimePeriod: {
      sameAs: !null,
    },
    form: ['suburbList'],
  },
  computed: {
    isDontKnowSelected() {
      return this.dontknow;
    },
    filterSuburbs() {
      function isNumeric(str) {
        if (typeof str !== 'string') return false;
        return !Number.isNaN(str) && !Number.isNaN(parseFloat(str));
      }

      if (this.userInput.length >= 4) {
        if (isNumeric(this.userInput)) {
          console.log('is numeric', this.userInput);
          return this.allSuburbsList.filter((x) => x.postcode.toString()
            .includes(this.userInput))
            .sort((a, b) => a.suburb.localeCompare(b.suburb));
        }
        return this.allSuburbsList.filter((x) => x.suburb.toLowerCase()
          .includes(this.userInput.toLowerCase()))
          .sort((a, b) => a.suburb.localeCompare(b.suburb));
      }
      return [];
    },
  },
  methods: {
    validate() {
      this.$v.form.$touch();
      const isValid = !this.$v.form.$invalid;
      this.$emit('on-validate', this.$data, isValid);
      return isValid;
    },
    tagValidator(tag) {
      let isValid = false;
      if (this.userInput.length >= 4) {
        this.allSuburbsList.forEach((x) => {
          if (tag === `${x.suburb}, ${x.postcode} (${x.state})`) {
            isValid = true;
            return isValid;
          }
          return isValid;
        });
      }
      return isValid;
    },
    onChangeDontKnow(checked) {
      if (checked) {
        this.includesurrounding = false;
        this.suburb = 'I don\'t know';
      } else {
        this.suburb = '';
        this.includesurrounding = this.surroundingToggleHistory;
      }
    },
    onChangeIncludeSurrounding(checked) {
      if (checked && this.dontknow) {
        this.dontknow = false;
        this.surroundingToggleHistory = true;
        this.suburb = '';
      }
      if (!checked) this.surroundingToggleHistory = false;
    },
  },
};

</script>

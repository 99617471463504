<template>
  <div class="d-flex flex-column flex-root oh-application">
    <div
      class="d-flex flex-row-fluid flex-column justify-content-center mx-auto oh-application-card"
    >
      <div class="card">
        <div class="card-body d-flex flex-column px-md-25 pt-md-19 pb-md-17">
          <form-wizard
            ref="wizard"
            shape="square"
            title=""
            subtitle=""
            stepSize="xs"
            color="#0039A9"
            errorColor="#0039A9"
          >
            <wizard-step
              slot-scope="props"
              slot="step"
              :tab="props.tab"
              :transition="props.transition"
              :index="props.index"
              :activeTabIndex="props.activeTabIndex"
              :class="lastStepClass(props)"
              >
            </wizard-step>
            <tab-content :before-change="()=>register()">
              <registration-form ref="register"></registration-form>
            </tab-content>
            <tab-content :before-change="()=>setReferralSource()">
              <referral-form ref="referral"></referral-form>
            </tab-content>
            <tab-content :before-change="()=>setTargetSuburb()">
              <target-suburb-form ref="suburb"></target-suburb-form>
            </tab-content>
            <tab-content :before-change="()=>setCobuyer()">
              <cobuyer-form ref="cobuyer"></cobuyer-form>
            </tab-content>
            <tab-content :before-change="()=>setFinancial()">
              <financial-form ref="financial"></financial-form>
            </tab-content>
            <template slot="footer" scope="props">
              <div :class="[{'last-step': isLastStep(props)}]">
                <div class=wizard-footer-left>
                  <wizard-button
                    v-if="!isPreviousDisabled(props)"
                    @click.native="props.prevTab()"
                    :style="previousButtonStyle">Previous</wizard-button>
                </div>
                <div class="wizard-footer-right">
                  <wizard-button
                    @click.native="props.nextTab()"
                    class="wizard-footer-right"
                    :style="props.fillButtonStyle">{{ rightButtonName(props) }}</wizard-button>
                </div>
              </div>
            </template>
          </form-wizard>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  FormWizard, TabContent, WizardButton, WizardStep,
} from 'vue-form-wizard';

import 'vue-form-wizard/dist/vue-form-wizard.min.css';

import { mapGetters, mapState } from 'vuex';
import { REGISTER } from '@/store/auth.module';
import { UPDATE_APPLICATION, SEND_APPLICATION } from '@/store/application.module';

import RegistrationForm from '@/views/Auth/components/RegistrationForm.vue';
import ReferralForm from '@/views/Application/components/ReferralForm.vue';
import TargetSuburbForm from '@/views/Application/components/TargetSuburbForm.vue';
import CobuyerForm from '@/views/Application/components/CobuyerForm.vue';
import FinancialForm from '@/views/Application/components/FinancialForm.vue';

import suburbsJson from '../../../assets/suburbs.json';

export default {
  name: 'Application',
  components: {
    FinancialForm,
    CobuyerForm,
    TargetSuburbForm,
    RegistrationForm,
    ReferralForm,
    FormWizard,
    TabContent,
    WizardButton,
    WizardStep,
  },
  data() {
    return {
      isTabFilled: true,
    };
  },
  computed: {
    ...mapState({
      errors: (state) => state.errors,
      application: (state) => state.application.form,
    }),
    ...mapGetters(['currentUser', 'isAuthenticated']),
    previousButtonStyle() {
      return {
        backgroundColor: '#FFFFFF',
        borderColor: '#0039A9',
        color: '#0039A9',
      };
    },
  },
  methods: {
    register() {
      return new Promise((resolve, reject) => {
        const registerForm = this.$refs.register;
        if (registerForm.validate()) {
          const formData = {
            name: registerForm.name,
            username: registerForm.email,
            phone: registerForm.phone,
            email: registerForm.email,
            password: registerForm.password,
          };
          this.$store
            .dispatch(REGISTER, formData)
            .then(() => {
              resolve(true);
            })
            .catch(() => {
              reject();
            });
        } else {
          reject();
        }
      });
    },
    setReferralSource() {
      return new Promise((resolve, reject) => {
        const referralForm = this.$refs.referral;
        if (referralForm.validate()) {
          const formData = {
            referralsource: referralForm.referralsource,
          };
          this.$store
            .dispatch(UPDATE_APPLICATION, formData)
            .then(() => {
              resolve(true);
            })
            .catch(() => {
              reject();
            });
        } else {
          reject();
        }
      });
    },
    setTargetSuburb() {
      return new Promise((resolve, reject) => {
        const suburbForm = this.$refs.suburb;
        if (suburbForm.validate()) {
          const suburb = !suburbForm.dontknow ? suburbForm.suburbList.toString() : 'I don\'t know';
          const formData = {
            suburb,
            suburbsJSON: [],
            includesurrounding: suburbForm.includesurrounding,
            moveintime: suburbForm.whenTimePeriod,
          };
          suburbForm.suburbList.forEach((s) => {
            suburbsJson.data.forEach((x) => {
              if (s === `${x.suburb}, ${x.postcode} (${x.state})`) {
                formData.suburbsJSON.push(x);
              }
            });
          });
          this.$store
            .dispatch(UPDATE_APPLICATION, formData)
            .then(() => {
              resolve(true);
            })
            .catch(() => {
              reject();
            });
        } else {
          reject();
        }
      });
    },
    setCobuyer() {
      return new Promise((resolve, reject) => {
        const cobuyerForm = this.$refs.cobuyer;
        if (cobuyerForm.validate()) {
          let formData = {};
          if (cobuyerForm.withCobuyer) {
            formData = {
              cobuyername: cobuyerForm.cobuyername,
              cobuyeremail: cobuyerForm.cobuyeremail,
            };
          }
          formData.dependents = cobuyerForm.dependents.toString();
          this.$store
            .dispatch(UPDATE_APPLICATION, formData)
            .then(() => {
              resolve(true);
            })
            .catch(() => {
              reject();
            });
        } else {
          reject();
        }
      });
    },
    setFinancial() {
      return new Promise((resolve, reject) => {
        const financialForm = this.$refs.financial;
        if (financialForm.validate()) {
          const {
            income, cobuyerincome, rent, depositsaved, monthlysavings,
          } = financialForm;
          const formData = {
            income,
            cobuyerincome,
            rent,
            depositsaved,
            monthlysavings,
          };
          this.$store
            .dispatch(UPDATE_APPLICATION, formData)
            .then(() => {
              this.$store.dispatch(SEND_APPLICATION, {
                ...this.application,
              })
                .then(() => {
                  this.$router.push('/dashboard');
                })
                .catch(() => {
                  console.log('Error');
                });
              resolve();
            })
            .catch(() => {
              reject();
            });
        } else {
          reject();
        }
      });
    },
    isPreviousDisabled(props) {
      return props.activeTabIndex <= 1;
    },
    rightButtonName(props) {
      return props.activeTabIndex === 4 ? 'See buying power' : 'Next';
    },
    lastStepClass(props) {
      const formWizard = this.$refs.wizard;
      const activeTab = formWizard.activeTabIndex;
      if (props.index < activeTab) return 'active';
      return '';
    },
    isLastStep(props) {
      return props.isLastStep;
    },
  },
  mounted() {
    if (this.isAuthenticated) {
      this.$refs.wizard.changeTab(0, 1);
    }
  },
};
</script>
<style lang="scss" scoped>
  .form-group {
    label {
      margin-bottom: 0;
    }
  }
</style>

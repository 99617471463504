<template>
  <div>
    <div class="form-wizard-header">
      <h2 class="text-uppercase font-weight-bolder mb-3">Let's get started</h2>
      <p class="mb-8">2 minutes to get qualified</p>
    </div>
    <form>
      <div class="form-group" v-bind:class="{ 'has-error': $v.name.$error }">
        <label class="font-size-xs">Name</label>
        <input class="form-control"
               v-model.trim="name">
        <span class="help-block"
              v-if="$v.name.$error && !$v.name.required">
          First name is required</span>
      </div>
      <div class="form-group" v-bind:class="{ 'has-error': $v.phone.$error }">
        <label class="font-size-xs">Phone number</label>
        <input class="form-control" v-model.trim="phone">
        <span class="help-block"
              v-if="$v.phone.$error && !$v.phone.required">
          Phone number is required</span>
      </div>
      <div class="form-group"
         v-bind:class="{ 'has-error': $v.email.$error }">
      <label class="font-size-xs">Email</label>
      <input class="form-control"
             v-model.trim="email">
        <span class="help-block"
            v-if="($v.email.$error && (!$v.email.email || !$v.email.required)) || isEmailTaken">
        Please choose a different email address</span>
    </div>
    </form>
  </div>
</template>
<script>
import { required, email } from 'vuelidate/lib/validators';
import { mapState } from 'vuex';

// github.com/brendanashworth/generate-password
const generator = require('generate-password');

export default {
  name: 'RegistrationForm',
  data() {
    return {
      name: '',
      phone: '',
      email: '',
      password: generator.generate({
        length: 10,
        numbers: true,
        symbols: true,
        uppercase: true,
        lowercase: true,
      }),
    };
  },
  validations: {
    name: {
      required,
    },
    phone: {
      required,
    },
    email: {
      required,
      email,
    },
    form: ['name', 'phone', 'email'],
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    isEmailTaken() {
      if (this.errors
        && this.errors.data
        && this.errors.data[0]
        && this.errors.data[0].messages
        && this.errors.data[0].messages[0].id) {
        return this.errors.data[0].messages[0].id === 'Auth.form.error.email.taken';
      }
      return false;
    },
  },
  methods: {
    validate() {
      this.$v.form.$touch();
      const isValid = !this.$v.form.$invalid;
      this.$emit('on-validate', this.$data, isValid);
      return isValid;
    },
  },
  created() {
    if (this.$route.query && this.$route.query.email) {
      this.email = this.$route.query.email;
    }
  },
};
</script>
<style lang="scss" scoped>
</style>

<template>
  <div>
    <div class="form-wizard-header">
      <h2 class="text-uppercase font-weight-bolder mb-10">
        TELL US ABOUT YOUR<br/>
        FINANCIAL SITUATION</h2>
      <p class="mb-8">We use this to calculate your budget.
        Include all types of income for accurate results.</p>
      <form>
        <b-form-group
          :class="{ 'has-error': $v.income.$error }">
          <label class="font-size-xs">Your pre-tax annual income</label>
          <currency-input
            v-model="income"
            class="form-control"
            placeholder="$100 000"
            v-bind="currencyInputOptions"
          />
          <span class="help-block"
                v-if="$v.income.$error && !$v.income.required">
          Income is required</span>
        </b-form-group>
        <b-form-group
          v-if="hasCobuyer"
          :class="{ 'has-error': $v.cobuyerincome.$error }">
          <label class="font-size-xs">Your co-buyer’s pre-tax annual income</label>
          <currency-input
            v-model="cobuyerincome"
            class="form-control"
            placeholder="$100 000"
            v-bind="currencyInputOptions"
          />
          <span class="help-block"
                v-if="$v.cobuyerincome.$error && !$v.cobuyerincome.required">
          Co-buyer income is required</span>
        </b-form-group>
        <b-form-group
          :class="{ 'has-error': $v.rent.$error }">
          <label class="font-size-xs">Your current rent per month</label>
          <currency-input
            v-model="rent"
            class="form-control"
            placeholder="$3 000"
            v-bind="currencyInputOptions"
          />
          <span class="help-block"
                v-if="$v.rent.$error && !$v.rent.required">
          Rent is required</span>
        </b-form-group>
        <b-form-group
          :class="{ 'has-error': $v.depositsaved.$error }">
          <label class="font-size-xs">How much have you saved to date toward a deposit?</label>
          <currency-input
            v-model="depositsaved"
            class="form-control"
            placeholder="$20 000"
            v-bind="currencyInputOptions"
          />
          <span class="help-block"
                v-if="$v.depositsaved.$error && !$v.depositsaved.required">
          Deposit saved is required</span>
        </b-form-group>
        <b-form-group
          :class="{ 'has-error': $v.monthlysavings.$error }">
          <label class="font-size-xs">
            How much do you typically save each month toward a deposit?</label>
          <b-form-select v-model="monthlysavings" :options="monthlysavingsOptions"></b-form-select>
          <span class="help-block"
                v-if="$v.monthlysavings.$error && !$v.monthlysavings.required">
          Monthly saving is required</span>
        </b-form-group>
      </form>
    </div>
  </div>
</template>
<script>
import { CurrencyInput } from 'vue-currency-input';
import { required, numeric, requiredUnless } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';

export default {
  name: 'FinancialForm',
  components: {
    CurrencyInput,
  },
  data() {
    return {
      currencyInputOptions: {
        currency: 'USD',
        locale: 'en',
        precision: 0,
        allowNegative: false,
        distractionFree: {
          hideNegligibleDecimalDigits: true,
        },
      },
      income: null,
      cobuyerincome: null,
      rent: null,
      depositsaved: null,
      monthlysavings: 'Below $1k per month',
      monthlysavingsOptions: [
        {
          value: 'Below $1k per month',
          text: 'Below $1k per month',
        },
        {
          value: '$1k - $3k per month',
          text: '$1k - $3k per month',
        },
        {
          value: 'Above $3k per month',
          text: 'Above $3k per month',
        },
      ],
    };
  },
  validations: {
    income: {
      required,
      numeric,
    },
    cobuyerincome: {
      required: requiredUnless('needInputValidation'),
      numeric: requiredUnless('needInputValidation'),
    },
    rent: {
      required,
      numeric,
    },
    depositsaved: {
      required,
      numeric,
    },
    monthlysavings: {
      required,
    },
    form: ['income', 'cobuyerincome', 'rent', 'depositsaved', 'monthlysavings'],
  },
  computed: {
    ...mapGetters(['currentUser', 'isAuthenticated', 'hasCobuyer']),
    needInputValidation() {
      return !this.hasCobuyer;
    },
  },
  methods: {
    validate() {
      this.$v.form.$touch();
      const isValid = !this.$v.form.$invalid;
      this.$emit('on-validate', this.$data, isValid);
      return isValid;
    },
  },
};

</script>

<style lang="scss">
  .cobuyer-fields {
    margin-top: 2.5rem;
  }
</style>

<template>
  <div>
    <div class="form-wizard-header">
      <h2 class="text-uppercase font-weight-bolder mb-10">
        How did you hear <br/>
        about ownHome?</h2>
      <form>
        <b-form-group
            :class="{ 'has-error': $v.referralsource.$error }">
          <b-form-radio
            v-model="referralsource"
            name="referralsource" value="word_of_mouth">Word of mouth</b-form-radio>
          <b-form-radio
            v-model="referralsource"
            name="referralsource" value="real_estate_agent">Real estate agent</b-form-radio>
          <b-form-radio
            v-model="referralsource"
            name="referralsource" value="onlne_article">Online article</b-form-radio>
          <b-form-radio
            v-model="referralsource"
            name="referralsource" value="online_ad">Online ad</b-form-radio>
          <b-form-radio
            v-model="referralsource" class="last"
            name="referralsource" value="other">Other</b-form-radio>
        </b-form-group>
      </form>
    </div>
  </div>
</template>
<script>
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'ReferralForm',
  data() {
    return {
      referralsource: 'word_of_mouth',
    };
  },
  validations: {
    referralsource: {
      required,
    },
    form: ['referralsource'],
  },
  methods: {
    validate() {
      this.$v.form.$touch();
      const isValid = !this.$v.form.$invalid;
      this.$emit('on-validate', this.$data, isValid);
      return isValid;
    },
  },
};

</script>
